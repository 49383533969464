<template>
  <div class="WordSection1">
    <h2
      style="
        background-color: #ffffff;
        font-size: 17pt;
        font-weight: normal;
        line-height: 122%;
        margin: 18pt 0pt 4pt;
        text-align: center;
      "
    >
      <a name="_a86klnhmrqu"></a
      ><span style="font-family: Nunito; font-size: 17pt">Privacy Policy</span>
    </h2>
    <p style="background-color: #ffffff; margin: 12pt 0pt; text-align: center">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Last Updated: October 1, 2021</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >This Privacy Policy describes how Roxe Inc and its affiliates and
        related parties (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Roxe</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) collect, use, and share any information that can be used to directly
        or indirectly personally identifies you (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Personal Data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) that we receive from you when you visit our website or app or which
        we otherwise receive or collect from you in the course of, or in
        connection with, the provision of our products and services and our
        business operations. Please also read our</span
      ><a style="color: #000000" href="https://www.roxe.io/glba-privacy-notice"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="https://www.roxe.io/glba-privacy-notice"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >Gramm-Leach-Bliley Act (GLBA) Privacy Notice</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"
        >, and if you are a California resident, please also review our
        California Privacy Notice.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >It answers the following questions:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we collect Personal Data about you?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >What Personal Data do we collect?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we use your Personal Data, and on what legal basis do we collect
        your Personal Data?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we keep your Personal Data safe?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we share your Personal Data?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Do we transfer your Personal Data outside of the European Union?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >What are your legal data privacy rights?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How long do we retain your Personal Data?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we protect children’s privacy?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we use your Personal Data to inform you and market to you?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >How do we make changes to this Privacy Policy?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Where does this Privacy Policy apply?</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Who can you contact if you have further questions or requests about
        your privacy?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >It is important that you read this Privacy Policy together with any
        other Privacy Policy or fair processing notice we may provide on
        specific occasions when we are collecting or processing Personal Data
        about you so that you are fully aware of how and why we are using your
        data. This Privacy Policy supplements the other notices and is not
        intended to override them.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we collect Personal Data about you?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We collect information from and about you when you register with us or
        use our products, services or apps (our “</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Services</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) or use our website or software applications, or access or use
        third-party services that use our Application Programming Interface
        (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >API</span
      ><span style="font-family: Nunito; font-size: 13pt">”).</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >When registering with us as a customer, we ask you for detailed
        information, which we will use to verify your identity and protect
        against fraud, among other reasons discussed below.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We gather Personal Data at other times when managing your Roxe account,
        such as from surveys, during support or customer care or during
        investigations.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We automatically collect information sent to us by your computer,
        mobile phone or other device to improve your experience.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >When you use a location-enabled device with our Service, we may collect
        geographical location data or use various means to determine your
        location, such as sensor data from your device that may, for instance,
        provide data on nearby cell towers and Wi-Fi access spots.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >As you interact with our website or software applications, we may
        automatically collect technical data about your equipment, browsing
        actions and patterns. We collect this information by using cookies,
        server logs, and other similar technologies. Cookies are small data
        files stored on your hard drive by a website. Cookies help us make our
        website and Service and your use of them better by allowing us to
        recognize your browser and capture and remember certain information.
        Please see our</span
      ><a style="color: #000000" href="https://www.roxe.io/cookie-policy"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="https://www.roxe.io/cookie-policy"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >Cookie Policy</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt">
        for additional information. You can also adjust your browser settings to
        disable cookies, but it may affect your ability to use the Service and
        our website.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt">Do Not Track (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >DNT</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) is a privacy preference that Individuals can set in certain web
        browsers. DNT is a privacy preference that Individuals can set in
        certain browsers to inform websites that they do not want certain
        information about their webpage visits collected over time and across
        websites. We do not track, plant cookies, or use advertising when a DNT
        browser mechanism is in place.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We may receive data from third parties about you. This may include
        technical data from analytics and advertising partners like Google, from
        identity verification providers, as well as from other financial
        institutions.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >What Personal Data do we collect?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Roxe only collects the Personal Data we need in order to offer and
        support our Service and meet our legal and regulatory obligations, which
        may vary depending on our relationship with you.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Here is an overview of the Personal Data we collect from or about
        you:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Identity verification data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: To verify your identity, we may collect your name, address, ID
        numbers, date of birth, and other similar information. We may also
        require you to provide additional Personal Data for verification
        purposes, including a copy of your government-issued identification and
        a selfie photo. We may obtain information from affiliated and
        non-affiliated third parties, such as credit bureaus, identity
        verification services, and other screening services to verify that you
        are eligible to use our Services, and will associate that information
        with the information we collected from you. As part of our identity
        verification process, Roxe uses IdentityMind by Acuant (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >IdentityMind</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) to verify your identity by determining whether a selfie you take
        matches the photo in your government-issued identification.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Financial data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: In addition to identity verification, Roxe may collect various
        information regarding your finances through third parties in order to be
        able to perform the transactions you request on the platform and to
        ensure compliance with regulatory requirements (</span
      ><span style="font-family: Nunito; font-size: 13pt; font-style: italic"
        >e.g</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >., anti-money laundering laws) and our own internal policies; in order
        for this to function, we must share certain elements of your Personal
        Data with these third parties. The information we receive from these
        third parties may include accounts you hold, balances, transactions, and
        a risk score. When linking your existing bank account to the Roxe
        platform, users in certain jurisdictions may use third-party services
        including Plaid and Stripe to seamlessly link accounts, and in order to
        do so, information about your bank account is shared by such third-party
        providers with Roxe. The collection and transfer of this information is
        subject to Plaid’s and Stripe’s privacy policy.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Account activity data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: We collect information about your transactions, payments from or to
        you, and your other activities on our site or Service and other details
        of products and services you have purchased from us.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Application use data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: We may collect data on your interaction and use of our Service. This
        includes visits to our website or app, sign-up activity, your bank
        account, credit card, other payment details to enable you to enter into
        transactions on the Service, along with any additional information you
        may disclose to our user support team in order to resolve problems you
        report.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Your device data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: We collect and process your internet protocol (IP) addresses, your
        login data, browser type and version, time zone setting and location,
        browser plug-in types and versions, operating system and platform and
        other technology on the devices you use to access this website or any of
        our software applications.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Marketing data</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >: We collect and process your preferences in receiving marketing from
        us, your email address, including hashed identifiers derived from email
        addresses for the purposes of cross-device tracking for targeted
        advertising, and where you may have seen Roxe advertisements.
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we use your Personal Data, and on what legal basis do we collect
        your Personal Data?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Listed below are the ways in which we may use and process your Personal
        Data and the legal grounds we rely on to do so.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Providing our Service to you:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        It may sound obvious, but we need your data to make Roxe work in the
        first place. Without your name, banking information and other details,
        we simply could not offer you our products and services.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Identity verification:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        As a financial institution, it is essential that we are able to confirm
        the true identity of our users. There are rules and regulations across
        the globe that require we identify our customers, including laws
        concerning anti-corruption, anti‐bribery, anti‐terrorism, and anti‐money
        laundering.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Fraud Prevention:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        We use the Personal Data we collect in connection with providing you our
        services to detect and prevent fraudulent activity. We share this
        information with IdentityMind, a third party service provider, to assist
        us with this effort. IdentityMind uses various technologies to capture
        various device identifiers, such as canvas fingerprinting and may use
        automated decision making in order to detect and prevent fraudulent
        activity. To learn more about IdentityMind, please see their Service
        Privacy Notice.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Account Security:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        We use your phone number for our account two-factor authentication
        process.  This includes sending SMS or text messages (including by an
        automatic telephone dialing system) to any of the phone numbers provided
        by you or on your behalf in connection with your Roxe account.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Keeping you informed:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        It is important for security and financial transparency that we keep you
        aware of your transactions and related activity at Roxe. We will send
        you emails with confirmations, invoices, technical notices, updates,
        security alerts, legal and support and administrative messages.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Investigations:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        Occasionally, we need to look at your account activity in order to check
        and protect against fraudulent, unauthorized or illegal behavior.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Customer care:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        We strive to make sure you can easily use our products with no
        headaches, but sometimes you need help. Our customer care team will
        occasionally need access to your account data in order to fix any issues
        or answer your questions.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Managing and improving our Service:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        We like to understand how our users engage and use Roxe in order to make
        sure Roxe is the best it can be. This process includes combining
        different account, transactional, marketing and other data to analyze
        the effectiveness and performance of our Service.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Behavioral Advertising and Analytics:</span
      ><span style="font-family: Nunito; font-size: 13pt">
        This process includes combining different account, transactional,
        marketing, and cross-device tracking. When we apply these techniques we
        may use your Personal Data to provide you with targeted advertisements
        or marketing communications we believe may be of interest to you. We may
        also use information from your mobile device, such as: browser type,
        device type and model, CPU, system language, memory, OS version, Wi-Fi
        status, time stamp and zone, device motion parameters and carrier for
        advanced attribution and analytical purposes including personalized or
        lookalike advertising.  For more information about how targeted
        advertising works, you can visit the Network Advertising Initiative’s
        (“NAI”) educational page here.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >You can opt-out of targeted advertising by using the links below:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt">Facebook:</span
      ><a
        style="color: #000000"
        href="https://www.facebook.com/settings/?tab=ads"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a
        style="color: #000000"
        href="https://www.facebook.com/settings/?tab=ads"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >https://www.facebook.com/settings/?tab=ads</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"> </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt">Google:</span
      ><a
        style="color: #000000"
        href="https://www.google.com/settings/ads/anonymous"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a
        style="color: #000000"
        href="https://www.google.com/settings/ads/anonymous"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >https://www.google.com/settings/ads/anonymous</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"> </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Google Analytics:</span
      ><a style="color: #000000" href="http://myactivity.google.com/"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="http://myactivity.google.com/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >http://myactivity.google.com/</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"> </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Additionally, you can opt-out of some of these services by visiting the
        Digital Advertising Alliance’s opt-out portal at:</span
      ><a style="color: #000000" href="http://optout.aboutads.info/"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="http://optout.aboutads.info/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >http://optout.aboutads.info/</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"
        >. Or by visiting any of the links below: </span
      ><a style="color: #000000" href="http://optout.networkadvertising.org/"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="http://optout.networkadvertising.org/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >http://optout.networkadvertising.org/</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"> or</span
      ><a style="color: #000000" href="http://youronlinechoices.eu/"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="http://youronlinechoices.eu/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >http://youronlinechoices.eu/</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt">. </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >For more information, see our</span
      ><a style="color: #000000" href="https://www.roxe.io/cookie-policy"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a style="color: #000000" href="https://www.roxe.io/cookie-policy"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >Cookie Policy</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt">.</span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Communicating with you: We want you to be aware of product updates,
        news, events, and promotions and, if you consent, we will send you
        information, including regular personalized emails, to keep you up to
        date with all things Roxe.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Business operations: We may need to use your Personal Data for our
        business operations, including internal training and administration,
        legal compliance, to enforce our legal rights, to protect third party
        rights, and in connection with a business transition such as a merger,
        acquisition by another company, or sale of all or a portion of our
        assets.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Website and software applications: We use data analytics to improve our
        website and software applications, activities and operations,
        products/services, events, etc.
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Third parties: If you (i) use our Service and/or platform by (a) using
        an application (whether directly or by authorizing an application to use
        our Service and/or platform), (b) using a link provided by a
        third-party, or (c) any third party integrations or (ii) if you partner
        with us in a program or other business arrangement where a third-party
        intermediary is involved  all such third parties may receive detailed
        information about your account, your use of the Service, transaction
        history or even the ability to take actions on your behalf. When you
        authorize an application or third party integration via the Roxe
        platform, you will be notified of what Personal Data will be shared with
        these third parties. Information collected by these applications or
        third-party integrations are subject to their terms and policies and are
        required by contract by Roxe to maintain the confidentiality and
        security of your Personal Data.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >The primary legal grounds upon which we collect and process your
        Personal Data are:
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >through obtaining your consent at the time of collection,
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >due to the necessity of doing so to perform the services as requested,
      </span>
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >in order to comply with our legal obligations, and</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >to pursue our legitimate interests (</span
      ><span style="font-family: Nunito; font-size: 13pt; font-style: italic"
        >e.g.,</span
      ><span style="font-family: Nunito; font-size: 13pt">
        to improve our services). 
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Note that we may process your Personal Data for more than one lawful
        basis depending on the specific purpose for which we are using that
        data.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we keep your Personal Data safe?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We have implemented security measures to prevent your Personal Data
        from being accidentally lost, used or accessed in an unauthorized way,
        altered or disclosed.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We protect your Personal Data by maintaining physical, electronic, and
        procedural safeguards, incorporating tested security technologies, in
        compliance with applicable laws. We may use network safeguards such as
        firewalls and data encryption, enforce physical access controls, and
        authorize access to Personal Data only for those people who require
        access to fulfill their job responsibilities.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >In addition, we limit access to your Personal Data to those employees,
        agents, contractors, and other third parties who have a business need to
        know. They will only process your Personal Data on our instructions and
        they are subject to a duty of confidentiality. Those with access to your
        Personal Data are carefully screened, periodically reevaluated, and are
        required to keep all your Personal Data confidential.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we share your Personal Data?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We sometimes share your information internally between employees and
        contractors of the Roxe (including those based in and outside the
        European Economic Area (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >EEA</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”)), in particular in connection with activities undertaken jointly or
        in common with such group members and/or provide IT and system
        administration services and undertake management reporting.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We do not sell, trade or otherwise transfer your Personal Data to third
        parties other than third parties who assist us in operating our Service,
        third parties who assist us in facilitating certain programs and other
        business arrangements for which you have expressly agreed to
        participate, management and reporting, maintaining compliance with
        relevant laws (including compliance with relevant anti-corruption,
        anti‐bribery, anti‐terrorism, and anti‐money laundering laws),
        conducting our business or supporting our users, or providing you with
        applications or services integrated via our API. We require that those
        third parties agree to keep this information confidential and secure on
        the same conditions and protection levels we provide to you as a user,
        in accordance with relevant privacy laws, including the European Union’s
        General Data Protection Regulation (“</span
      ><span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >GDPR</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >”) (to the extent applicable).</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We may also release your information to certified and authorized law
        enforcement officials when we believe release is appropriate to comply
        with the law, enforce our terms or policies, or protect the rights,
        property, or safety of Roxe, our users, or others. We have a set of
        guidelines for how we engage with law enforcement officials that are
        available to the public here.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Finally, in the event of the sale or transfer of ownership, your data
        would be shared with the new owners.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >(To the extent applicable for EU customers) Do we transfer your
        Personal Data outside of the EU?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Roxe is headquartered in the United States. Many of our affiliates and
        third-party service providers are based outside the EEA, so processing
        of your Personal Data may involve a transfer of your Personal Data
        outside the EEA and may be maintained or accessed in servers or files
        located in countries outside the EEA, including the United States.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >By voluntarily providing your Personal Data on or via this website or
        app, you consent to its transfer, processing and storage in the United
        States or other countries outside the EEA, some which have not been
        deemed by the EEA to have “adequate” privacy safeguards.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Whenever we transfer any Personal Data outside the EEA, we will put in
        place an adequate level of protection to ensure that any such transfers
        comply, and are consistent with applicable EU and/or UK data protection
        laws, including with respect to transfers among Roxe affiliates, the
        standard data protection clauses adopted by the EU Commission.
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Please contact us at support@roxe.io if you want further information on
        the specific mechanism used by us when transferring Personal Data
        outside of the EEA.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >What are your legal data privacy rights?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >You have the right to:</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Access the Personal Data we store and process about you or to request a
        copy of it to transfer to another service provider.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Request that we correct inaccurate data about you.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Ask us to delete or object to us processing your data, although for
        legal reasons (e</span
      ><span style="font-family: Nunito; font-size: 13pt; font-style: italic"
        >.g.</span
      ><span style="font-family: Nunito; font-size: 13pt"
        >, anti-money laundering rules and regulations) we cannot always do
        this.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Withdraw your consent to receive marketing emails.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Your marketing preferences can be updated on the Roxe Platform under
        your user settings or through any Newsletter via the “Unsubscribe”
        link.  For all other Data Privacy inquiries, please submit your request
        through our standard customer support process, by contacting us at
        support@roxe.io. Also, under the relevant privacy laws of the EEA
        (including the European Union’s</span
      ><a
        style="color: #000000"
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&amp;from=EN"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a
        style="color: #000000"
        href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&amp;from=EN"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >GDPR</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt"
        >) in respect of your EEA Personal Data you may have a number of
        important rights. For further information on each of those rights,
        including the circumstances in which they apply, see the Guidance from
        the UK Information Commissioner’s Office (ICO) on individual’s rights
        under the GDPR available</span
      ><a
        style="color: #000000"
        href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
        ><span style="font-family: Nunito; font-size: 13pt"> </span></a
      ><a
        style="color: #000000"
        href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >here</span
        ></a
      ><span style="font-family: Nunito; font-size: 13pt">.</span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How long do we retain your Personal Data?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Roxe maintains reasonable procedures to help ensure that your Personal
        Data is reliable for its intended use, accurate, complete, and
        current.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 13pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >We will only retain your Personal Data for as long as necessary to
        fulfill the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements, as well as
        other factors required by the GDPR or other laws to which we are
        subject.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >When it is no longer necessary to retain your Personal Data, we will
        securely delete it, subject to applicable law and regulations.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >In some circumstances, we may anonymize your Personal Data (so that it
        can no longer be associated with you) for research or statistical
        purposes in which case we may use this information indefinitely without
        further notice to you.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 0pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >If you request that we stop sending you marketing materials, we will
        continue to keep a record of your contact details and appropriate
        information to enable us to comply with your request not to be contacted
        by us.</span
      >
    </p>
    <p
      style="
        background-color: #ffffff;
        margin: 0pt 0pt 13pt 36pt;
        text-indent: -18pt;
      "
    >
      <span style="font: 7pt 'Times New Roman'"
        >&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span
      ><span style="font-family: Nunito; font-size: 13pt"
        >Should you make a Data Subject request through our standard customer
        support channel, we will retain the minimum required amount of data in
        order to keep a record of your request and the action we took to resolve
        it. 
      </span>
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Please be aware that we may keep your Personal Data for five years or
        more from the date you cease to be our customer, depending on applicable
        law or regulations in the jurisdictions we operate.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we protect children’s privacy?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >We do not knowingly solicit or collect information from individuals
        under 18. If we become aware that a child under the age of 18 has
        provided us with Personal Data, we will close the account and restrict
        their information. If you believe that we might have collected Personal
        Data from a child under 18, please contact us using the information
        below.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we use your Personal Data to inform you and market to you?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >In compliance with applicable laws, we may send you personalized
        marketing information, including by email, and such information may
        include product and service updates, industry news, our events,
        activities and offers, information about our business and personnel, and
        tips. We may combine your Personal Data such as age, transaction
        history, account usage to improve the value and specificity of these
        communications.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >You can ask us to stop sending you marketing messages or information at
        any time by following the “unsubscribe” link on any marketing message
        sent to you or by updating your contact preferences directly on the Roxe
        Platform. Please note, it may take up to 10 days for this to take
        effect. Where you opt-out of receiving these marketing messages or
        publications, this will not apply to Personal Data collected by or
        provided to us in connection with a specific purpose, request, order,
        event or activity or any dealings with you, and you may still receive
        emails from us relating to the operation of the Roxe service, like
        transaction confirmations.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >How do we make changes to this Privacy Policy?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >This version was last updated on the date indicated above and historic
        versions are archived and can be obtained by contacting us. We may
        update this Privacy Policy from time to time as we deem necessary or
        appropriate in our sole discretion. If there are any material changes to
        this Privacy Policy, we will notify you by email, by means of a notice
        on the Roxe Platform, or as otherwise required by applicable law. We
        encourage you to review this Privacy Policy periodically to be informed
        regarding how we are using and protecting your information and to be
        aware of any policy changes. Any changes to this Privacy Policy take
        effect immediately after being posted or otherwise provided by us.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >It is important that the Personal Data we hold about you is accurate
        and current. Please keep us informed if your Personal Data changes
        during your relationship with us.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Where does this Privacy Policy apply?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >This Privacy Policy applies to all of the services offered by Roxe and
        services offered on third-party sites, such as advertising services.
        This Privacy Policy does not apply to services that have separate
        privacy notices that do not incorporate this Privacy Policy</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >This Privacy Policy doesn’t apply to the information practices of other
        companies and organizations that advertise our services or to services
        offered by other companies or individuals, including products or sites
        that may include our Services or be linked from our Services.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >This website and app may include links to third-party websites,
        plug-ins, and applications. Clicking on those links or enabling those
        connections may allow third parties to collect or share data about you.
        We do not control these third-party websites and are not responsible for
        their privacy policies. When you leave our website, we encourage you to
        read the Privacy Policy of every website you visit.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Who can you contact if you have further questions or requests about
        your privacy?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >You can direct any questions or complaints about the use or disclosure
        of your Personal Data to us by contacting us as set out below. We will
        investigate and attempt to resolve any complaints or disputes regarding
        the use or disclosure of your Personal Data as soon as possible.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >If you have any questions about this Privacy Policy or need to contact
        us, please email us at support@roxe.io, or contact us by mail at 8 The
        Green, Ste A, Dove, DE 19901. All Data Subject Requests should be
        submitted to our Customer Support Team.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >If you are resident in the European Union and are dissatisfied with the
        resolution of your complaint in respect of your EEA Personal Data, you
        may contact the EU data protection authority in your jurisdiction using
        the contact details provided here for further information and
        assistance.</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt; font-weight: bold"
        >Who can you contact if you have further questions or complaints about
        Roxe?</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >If you have a complaint, first contact Roxe Inc at 1-800-851-5339 or
        complaints@roxe.io and if you still have an unresolved complaint
        regarding Roxe, please direct your complaint to:</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >Consumer Financial Protection Bureau</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt">
      <span style="font-family: Nunito; font-size: 13pt"
        >855-411-2372, 855-729-2372 (TTY/TDD)</span
      >
    </p>
    <p style="background-color: #ffffff; margin: 12pt 0pt 0pt">
      <a style="color: #000000" href="http://www.consumerfinance.gov/"
        ><span
          style="
            font-family: Nunito;
            font-size: 13pt;
            text-decoration: underline;
          "
          >www.consumerfinance.gov</span
        ></a
      >
    </p>
    <p style="font-size: 11pt; line-height: 115%; margin: 0pt">
      <span style="font-family: DM Sans font-size: 11pt">&#xa0;</span>
    </p>
  </div>
</template>

<style lang="less" scoped>
.WordSection1 {
  padding: 30px;
  background: #fff;
  text-align: left;
  font-family: "DM Sans", Avenir, Helvetica, Arial, sans-serif !important;
}
.MsoNormal {
  font-size: 16px;
  line-height: 24px;
  color: #818e9e;
  background: #fff;
  margin-bottom: 14pt;
}
@media screen and (max-width: 991px) {
  .WordSection1 {
    padding-top: 70px;
  }
}
@media screen and (max-width: 575px) {
  .WordSection1 {
    padding: 70px 20px 80px;
  }
}
</style>
