
import {
  defineComponent,
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
  reactive,
  nextTick,
} from "vue";
import Header from "@/components/header/index.vue";
import Tail from "@/components/tail/index.vue";
import { ShowDialog, CodeList } from "@/helper";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Home",
  components: { Header, Tail },
  methods: {},

  setup() {
    const bkcolor = ref("white");
    const router = useRouter();
    const data = reactive({
      first: {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      },
      email: {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      },
      last: {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      },
      website: {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      },
      message: "",
    });
    const resetdata = () => {
      data.first = {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      };
      data.email = {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      };
      data.last = {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      };

      data.website = {
        value: "",
        notnull: true,
        error: false,
        msg: "This field is required",
      };
      data.message = "";
    };

    const checkdata = () => {
      if (data.first.value == "") {
        data.first.msg = "This field is required";
        data.first.error = true;
        ShowDialog("One or more fields have an error. Please check and try again.");
        return true;
      }
      data.first.error = false;

      let reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
      if (reg.test(data.email.value) == false) {
        data.email.msg = "E-mail address is invalid";
        data.email.error = true;
        ShowDialog("One or more fields have an error. Please check and try again.");
        return true;
      }
      data.email.error = false;

      if (data.last.value == "") {
        data.last.msg = "This field is required";
        data.last.error = true;
        ShowDialog("One or more fields have an error. Please check and try again.");
        return true;
      }
      data.last.error = false;
      if (data.website.value == "") {
        data.website.msg = "This field is required";
        data.website.error = true;
        ShowDialog("One or more fields have an error. Please check and try again.");
        return true;
      }
      data.website.error = false;
      return false;
    };

    const winScroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > 80) {
        bkcolor.value = "white";
      } else {
        bkcolor.value = "white";
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", winScroll);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", winScroll);
    });

    const _load_script = function (url: string, callback: any) {
      let head: any = document.querySelector("head");
      let script: any = document.createElement("script");
      let r = false;
      script.type = "text/javascript";
      script.src = url;
      if (callback) {
        script.onload = script.onreadystatechange = function () {
          if (!r && (!this.readyState || this.readyState == "complete")) {
            r = true;
            callback();
          }
        };
      }
      head.appendChild(script);
    };
    const win: any = window;
    win._show_thank_you = () => {
      console.log("");
    };
    win._show_error = (id: string, message: string) => {
      console.log("");
      console.log(message);
    };
    _load_script(
      "//d3rxaij56vjege.cloudfront.net/form-serialize/0.3/serialize.min.js",
      function () {
        win._form_serialize = win.serialize;
        console.log(win._form_serialize);
      }
    );
    let loading = false;
    const forms = "_form_17_";

    const handsubmit = () => {
      if (checkdata()) {
        return;
      }

      if (loading) return;
      loading = true;

      var serialized = win._form_serialize(document.getElementById(forms)).replace(/%0A/g, "\\n");
      console.log(`json:${serialized}`);
      _load_script(
        "https://apifiny.activehosted.com/proc.php?" + serialized + "&jsonp=true",
        () => {
          //reset date
          resetdata();
          ShowDialog("We have received your request. Our team will get in touch with you soon.");
          loading = false;
        }
      );
    };

    const handfirst = (first = true) => {
      if (first) {
        if (data.first.value == "") {
          data.first.msg = "This field is required";
          data.first.error = true;
          return true;
        }
        data.first.error = false;
      } else {
        if (data.last.value == "") {
          data.last.msg = "This field is required";
          data.last.error = true;
          return true;
        }
        data.last.error = false;
      }
    };

    const handsecond = () => {
      if (data.website.value == "") {
        data.website.msg = "This field is required";
        data.website.error = true;
        return true;
      }

      data.website.error = false;
    };

    const handlemail = () => {
      let reg = new RegExp("^(\\w+\\.*\\w+)*@(\\w+\\.*\\w+)*$");
      if (reg.test(data.email.value) == false) {
        data.email.msg = "E-mail address is invalid";
        data.email.error = true;
        return true;
      }
      data.email.error = false;
    };

    return { handfirst, handsecond, handlemail, data, bkcolor, handsubmit };
  },
});
